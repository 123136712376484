import React, { Component } from "react";
import $ from "jquery";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import { compose } from "redux";
import Service from "../FacilityDashoardService";
import actions from "../../../../store/facility/action";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PermissionService from "../../../Permissions/PermissionService.js";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { debounce } from "throttle-debounce";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import moment from "moment";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";

class OfficerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.Id,
      spinner: true,
      editPositions: [],
      pendingRequests: [],
      editPositionSizePerPage: 5,
      pendingRequestSizePerPage: 5,
      sizePerPage: 5,
      positions: [],
      membersSearchObj: {
        SearchTerms: "",
        Offset: 0,
        Fetch: 10,
      },
      dataLoadOnShow: false,
      //On which edit position search applied
      searchAppliedIndex: -1,
      membersData: [],
    };
    this.getOfficersWithPosition = this.getOfficersWithPosition.bind(this);
    this.editPositionSizePerPageChange = this.editPositionSizePerPageChange.bind(
      this
    );
    this.OnSubmit = this.OnSubmit.bind(this);
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.sizePerPageChange = this.sizePerPageChange.bind(this);
    this.loadMembers = this.loadMembers.bind(this);

    this.pendingOfficerRequest = this.pendingOfficerRequest.bind(this);
    this.loadData = this.loadData.bind(this);
    this.showSpinner2 = this.showSpinner2.bind(this);
    this.hideSpinner2 = this.hideSpinner2.bind(this);
    //this.history = this.getHistory.bind(this);
  }
  //getHistory = () =>{
  //    return useHistory();
  //}
  async showSpinner2() {
    await this.setState({ spinner: true });
  }
  async hideSpinner2() {
    await this.setState({ spinner: false });
  }
  async getOfficersWithPosition() {
    this.showSpinner2();

    let id = this.state.id && this.state.id > 0 ? this.state.id : this.props.Id;
    let data = await Service.GetOfficersPositions(id);
    await this.setState({ positions: data });
    this.hideSpinner2();
  }
  handleOnSelectIndividual = (e, id, teampositionid) => {
    let checked = this.state.positions;
    checked = checked.map((item) => {
      if (item.teamPositionId == teampositionid && item.id == id) {
        item.checked = !item.checked;
      }
      return item;
    });
    /* The Reason why we didn't use filter directly is because we want to add some object from edit position.
     * If we use filter directly instead of map then it will map only positions state obj */
    let checkedItems = _.cloneDeep(checked)
      .map((x) => {
        if (x.checked) {
          if (x.teamPositionId == teampositionid && x.id == id) {
            x.searchObj = _.clone(this.state.membersSearchObj);
            x.membersData = _.cloneDeep(this.state.membersData);
            x.effectiveDate =
              new Date(x.startDate) > new Date()
                ? moment(x.startDate.split("T")[0]).format("YYYY-MM-DD")
                : moment(new Date()).format("YYYY-MM-DD");
            x.facilityId = this.state.id;

            x.member_msg = "";
            x.member_err = false;
            x.memberID = null;
            x.memberName = null;
            //  x.member =  {
            //    id: x.memberID,
            //    name: x.memberName
            //};
          } else {
            if (!_.isEmpty(this.state.editPositions)) {
              let obj = this.state.editPositions.find((y) => y.id == x.id);
              if (obj) {
                return _.clone(obj);
              }
            }
          }
          return x;
        }
      })
      .filter(function(el) {
        return el;
      });

    this.setState({
      editPositions: _.cloneDeep(checkedItems),
      positions: checked,
    });
  };

  getColumn() {
    return [
      {
        dataField: "checked",
        headerClasses: "text-nowrap",
        headerAttrs: {
          width: "5%",
        },
        formatter: (cellContent, row, rowIndex /*, extraData*/) => {
          return (
            <div className="card-text pl-4 custom-checkbox">
              <input
                className="custom-control-input custom-control-input-override"
                type="checkbox"
                name={"checkbox" + rowIndex}
                id={"checkbox" + rowIndex}
                checked={row.checked}
                // onChange={(e) => {
                //   row.checked = e.target.checked;

                //   this.setState({
                //     myRequestData: this.state.myRequestData,
                //     checkAll: false,
                //   });
                // }}
              />

              <label
                className="custom-control-label custom-control-label-override"
                for={"checkbox" + rowIndex}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  this.handleOnSelectIndividual(e, row.id, row.teamPositionId);
                }}
              />
            </div>
          );
        },
      },
      {
        text: "Position Name",
        dataField: "positionName",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2",
      },
      {
        dataField: "memberName",
        text: "Member Name (Reg/Fac)",
        sort: true,
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
        formatter: (cellContent, row, rowIndex /*, extraData*/) => {
          return (
            <div>
              {row.id != null ? (
                <a
                  alt="show Member profile"
                  href={"/directory/userAction/" + row.memberID}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();

                    //              this.props.history.push("/directory/userAction/" + row.memberID);
                  }}
                >
                  {row.memberName} ({row.region != null ? row.region : "--"}/
                  {row.facility != null ? row.facility : "--"})
                </a>
              ) : (
                <i className="text-secondary">vacant position</i>
              )}
            </div>
          );
        },
      },

      {
        text: "Effective Date",
        dataField: "startDate",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2",
        sort: true,
        formatter: (cellContent, row) => {
          if (row.startDate != null && row.startDate != "0001-01-01T00:00:00") {
            return (
              <div>
                {row.startDate &&
                  moment(row.startDate.split("T")[0]).format("MM/DD/YYYY")}
              </div>
            );
          } else {
            return "";
          }
        },
      },
    ];
  }

  SearchHandler = (SearchTerms, rowIndex) => {
    this.state.editPositions[rowIndex].searchObj.SearchTerms = SearchTerms;
    this.setState(
      { editPositions: this.state.editPositions, searchAppliedIndex: rowIndex },
      () => {
        this.autocompleteSearchDebounced();
      }
    );
  };
  OnChange = (value, rowIndex) => {
    let obj = this.state.positions.find(
      (x) => x.id == this.state.editPositions[rowIndex].id
    );
    if (!value || value.id == "" || value.id == null || value.id == undefined) {
      this.state.editPositions[rowIndex].member_msg = "Please select a member!";
      this.state.editPositions[rowIndex].member_err = true;
    } else if (value.id == obj.memberID) {
      this.state.editPositions[rowIndex].member_msg =
        "The selected member is already working on this position!";
      this.state.editPositions[rowIndex].member_err = false;
    } else {
      this.state.editPositions[rowIndex].member_msg = "";
      this.state.editPositions[rowIndex].member_err = false;
    }
    this.state.editPositions[rowIndex].member = value;
    this.state.editPositions[rowIndex].memberID = value ? value.id : null;
    this.state.editPositions[rowIndex].memberName = value ? value.name : null;
    this.setState({ editPositions: this.state.editPositions });
  };
  OnSubmit = async () => {
    this.showSpinner();
    let valid = true;
    this.state.editPositions.forEach((e, index) => {
      if (
        !e.memberID ||
        e.memberID == null ||
        e.memberID == undefined ||
        e.memberID == ""
      ) {
        e.member_msg = "Please select a member!";
        e.member_err = true;
        valid = false;
      }
      if (valid) {
        let datevalid = this.validateDate(
          this.filterLatestDates(this.state.positions),
          e.teamPositionId,
          e.effectiveDate
        );
        if (!datevalid) {
          valid = false;
          e.effectiveDate_err = this.date_validation_msg;
        } else {
          e.effectiveDate_err = "";
        }
      }
    });
    if (valid) {
      let OfficersRequestBody = this.state.editPositions.map((x, index) => {
        return {
          TeamPositionMemberId: x.id,
          TeamPositionId: x.teamPositionId,
          MemberId: x.memberID,
          FacilityId: x.facilityId,
          EffectiveDate: x.effectiveDate,
        };
      });
      let result = await Service.CreateOfficersPositionsRequest(
        OfficersRequestBody
      );
      if (result) {
        this.handleClose();
        window.location.reload(true);
      }
    } else {
      this.setState({ editPositions: this.state.editPositions });
      toast.error("Please fix the error on form!");
    }
    this.hideSpinner();
  };
  filterLatestDates = (dataArray) => {
    const latestDates = dataArray.reduce((accumulator, current) => {
      const existing = accumulator.find(
        (item) => item.teamPositionId === current.teamPositionId
      );
      if (
        !existing ||
        new Date(existing.effectiveDate) < new Date(current.effectiveDate)
      ) {
        return [
          ...accumulator.filter(
            (item) => item.teamPositionId !== current.teamPositionId
          ),
          current,
        ];
      }
      return accumulator;
    }, []);

    return latestDates;
  };

  // Check if the room is available based on the provided date
  validateDate = (filteredPositionForDate, teamPositionId, providedDate) => {
    const teamPosition = filteredPositionForDate.find(
      (item) => item.teamPositionId === teamPositionId
    );
    const teamPositionDate = new Date(teamPosition.startDate);
    const providedDateTime = new Date(providedDate);

    if (teamPositionDate > providedDateTime) {
      return false;
    } else {
      return true;
    }
  };
  autocompleteSearch = () => {
    this._fetch();
  };
  _fetch = async () => {
    var data = await this.loadMembers();
  };
  async loadMembers(isDefaultSearch = false) {
    let id = this.state.id && this.state.id > 0 ? this.state.id : this.props.Id;
    if (isDefaultSearch) {
      this.showSpinner2();

      let membersSearchObj = this.state.membersSearchObj;
      let data = await PermissionService.LoadOfficerMembers(
        membersSearchObj.SearchTerms,
        membersSearchObj.Offset,
        membersSearchObj.Fetch
        // id
      );
      if (!_.isEmpty(data)) {
        this.state.membersData = _.filter(data.result, {
          memberType: "Current Member",
        });

        await this.setState({
          membersData: data.result,
        });
      }
      this.hideSpinner2();
    } else {
      let membersSearchObj = this.state.editPositions[
        this.state.searchAppliedIndex
      ].searchObj;
      let data = await PermissionService.LoadOfficerMembers(
        membersSearchObj.SearchTerms,
        membersSearchObj.Offset,
        membersSearchObj.Fetch
        // id
      );
      if (!_.isEmpty(data)) {
        this.state.editPositions[
          this.state.searchAppliedIndex
        ].membersData = _.filter(data.result, { memberType: "Current Member" });

        await this.setState({
          editPositions: this.state.editPositions,
        });
      }
    }
  }
  handleChangStartDate = (date, rowIndex) => {
    // validate the date is greater than other date or not.
    // Filtered data with the latest effective date for each room

    this.state.editPositions[rowIndex].effectiveDate = moment(date).format(
      "MM/DD/YYYY"
    );
    if (
      this.validateDate(
        this.filterLatestDates(this.state.positions),
        this.state.editPositions[rowIndex].teamPositionId,
        date
      )
    ) {
      this.state.editPositions[rowIndex].effectiveDate_err = "";
    } else {
      this.state.editPositions[
        rowIndex
      ].effectiveDate_err = this.date_validation_msg;
    }
    this.setState({ editPositions: this.state.editPositions });
  };
  date_validation_msg =
    "The provide date is overlaping with the other member timeframe.";
  getEditColumn() {
    return [
      {
        text: "Position Name",
        dataField: "positionName",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2",
        headerAttrs: {
          width: "33%",
        },
      },
      {
        dataField: "memberName",
        text: "Member Name",
        sort: true,
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
        formatExtraData: JSON.stringify(this.state.editPositions),
        headerAttrs: {
          width: "33%",
        },
        formatter: (cellContent, row, rowIndex /*, extraData*/) => {
          return (
            <>
              <Autocomplete
                options={row.membersData}
                filterOptions={(options, state) => options}
                id={"memberSearch" + rowIndex}
                autoComplete={true}
                autoHighlight={true}
                onInputChange={(e, value, reason) => {
                  this.SearchHandler(value, rowIndex);
                }}
                value={row.member}
                onChange={(e, value) => {
                  this.OnChange(value, rowIndex);
                }}
                getOptionLabel={(option) => {
                  if (option != undefined) {
                    return (
                      option.lastName +
                      " " +
                      option.firstName +
                      " (  " +
                      option.facility +
                      " / " +
                      option.region +
                      " )"
                    );
                  } else {
                    return "";
                  }
                }}
                // renderOption={(option) => (
                //   <React.Fragment>{option.name}</React.Fragment>
                // )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      className:
                        "pb-1" +
                        (params.inputProps &&
                          " " + params.inputProps.className),
                    }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      classes: { shrink: "mt-n1" },
                    }}
                    placeholder="Please Select New Member..."
                    fullWidth
                  />
                )}
              />
              <span
                className={`${row.member_err ? "text-danger" : "text-warning"}`}
              >
                {row.member_msg}
              </span>
            </>
          );
        },
      },
      {
        text: "Effective Date",
        dataField: "effectiveDate",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2",
        sort: true,
        headerAttrs: {
          width: "33%",
        },
        formatExtraData: JSON.stringify(this.state.editPositions),

        formatter: (cellContent, row, rowIndex) => {
          return (
            <div>
              <DatePicker
                className="form-control"
                dateForm="MM/DD/YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                onChange={(date) => {
                  this.handleChangStartDate(date, rowIndex);
                }}
                selected={
                  row.effectiveDate
                    ? moment(row.effectiveDate).toDate()
                    : moment(new Date()).toDate()
                }
                placeholderText={"MM/DD/YYYY"}
              />
              <span className="text-danger">{row.effectiveDate_err}</span>
            </div>
          );
        },
      },
    ];
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.Id != this.state.id) {
      this.setState(
        {
          id: nextProps.Id,
        },
        () => {
          if (this.state.id > 0) {
            this.loadData();
          }
        }
      );
    }
    //if (
    //  JSON.stringify(nextProps.PendingRequests) !=
    //  JSON.stringify(this.state.pendingRequests)
    //) {
    //    $("#OfficerSpinner").show();
    //    this.setState({ pendingRequests: nextProps.PendingRequests });
    //    $("#OfficerSpinner").hide();
    //}
  }
  pendingOfficerRequest = async () => {
    this.showSpinner2();
    let id = this.state.id && this.state.id > 0 ? this.state.id : this.props.id;
    let data = null;
    if (
      AuthService.canSPUpdate(PermissionFeature.OfficerUpdate, undefined, id)
    ) {
      data = await Service.GetPendingOfficersPositionsRequest(id);
    }

    if (data && data != null && !_.isEmpty(data)) {
      this.setState({ pendingRequests: data });
      this.props.showPendingRequestAlert(true);
    } else {
      this.setState({ pendingRequests: [] });
      this.props.showPendingRequestAlert(false);
    }
    this.hideSpinner2();
  };
  componentDidMount() {
    if (this.state.dataLoadOnShow == false) {
      this.loadData();
    }
  }
  sizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPage: sizePerPage });
  };
  editPositionSizePerPageChange = (sizePerPage) => {
    this.setState({ editpositionSizePerPage: sizePerPage });
  };
  pendingRequestSizePerPageChange = (sizePerPage) => {
    this.setState({ pendingRequestSizePerPage: sizePerPage });
  };
  RemotePagination(columns, keyFields, data, SizePerPageChange, sizePerPage) {
    let paginationOptions = {
      totalSize: data && data.length,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
    };
    return (
      <div>
        <BootstrapTable
          keyField={keyFields}
          data={data}
          columns={columns}
          className="table"
          pagination={
            data.length > sizePerPage
              ? paginationFactory({ ...paginationOptions })
              : false
          }
        />
      </div>
    );
  }

  handleClose = () => {
    this.props.showOfficerModal(false);

    this.setState({
      editPositions: [],
      editPositionSizePerPage: 5,
      sizePerPage: 5,
      positions: [],
      dataLoadOnShow: true,
      membersSearchObj: {
        SearchTerms: "",
        Offset: 0,
        Fetch: 10,
      },
      //On which edit position search applied
      searchAppliedIndex: -1,
      membersData: [],
    });
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };
  loadData = () => {
    if (
      (this.state.id && this.state.id > 0) ||
      (this.props.Id && this.props.Id > 0)
    ) {
      this.getOfficersWithPosition();
      this.pendingOfficerRequest();
      this.loadMembers(true);
    } else {
      $("#OfficerSpinner").hide();
    }
  };
  onShow = () => {
    //first time to load it on componentDidMount
    if (this.state.dataLoadOnShow) {
      this.loadData();
    }
  };

  changeHandlerName = (e) => {
    this.setState({ recipientName: e.target.value });

    if (e.target.value == null || e.target.value == "") {
      this.state.recipientName_err = "Please Provide Recipient Name";
    } else {
      this.state.recipientName_err = "";
    }

    this.setState({
      recipientName: e.target.value,
      recipientName_err: this.state.recipientName_err,
    });
  };

  render() {
    return (
      <>
        <Modal
          className="OfficerModal latest"
          show={this.props.facilityDashboardState.showOfficerModal}
          onHide={this.handleClose}
          centered
          size="xl"
          onShow={this.onShow}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-uppercase text-white mt-0">
                {" "}
                Local Update{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 className="text-center text-white bg-secondary w-100">
              CURRENT OFFICER
            </h3>
            <div className="row mx-0">
              <div className="col-12">
                {!_.isEmpty(this.state.positions) ? (
                  this.RemotePagination(
                    this.getColumn(),
                    "id",
                    this.state.positions,
                    this.sizePerPageChange,
                    this.state.sizePerPage
                  )
                ) : (
                  <p className="mt-4 text-center">No Positions Are Available</p>
                )}
              </div>
            </div>
            {AuthService.canSPView(
              PermissionFeature.OfficerUpdate,
              undefined,
              this.state.id
            ) && (
              <>
                <h3 className="text-center text-white bg-secondary w-100">
                  ASSIGN NEW OFFICER
                </h3>
                <div className="row mx-0">
                  <div className="col-12">
                    {!_.isEmpty(this.state.editPositions) ? (
                      this.RemotePagination(
                        this.getEditColumn(),
                        "id",
                        this.state.editPositions,
                        this.editPositionSizePerPageChange,
                        this.state.editPositionSizePerPage
                      )
                    ) : (
                      <p className="mt-4 text-center">
                        No Positions Are Available for Edit
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}
            {this.state.spinner && (
              <div className="text-center" id="OfficerSpinner">
                <div class="spinner-border text-success" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {!_.isEmpty(this.state.editPositions) &&
              AuthService.canSPView(
                PermissionFeature.OfficerUpdate,
                undefined,
                this.state.id
              ) && (
                <div className="px-4 mx-0 text-right">
                  <button
                    className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                    type="button"
                    onClick={this.handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={this.OnSubmit}
                    className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default connect(
  (state) => ({
    facilityDashboardState: state.facilityDashboard,
  }),
  {
    ...actions,
  }
)(OfficerModal);
