import React, { Component } from "react";

import { Collapse, Card, CardBody, Spinner, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import actions from "../../../store/facility/action";
import _ from "lodash";

import PhoneModal from "./Modals/PhoneModal";
import PhysicalAddressModal from "./Modals/PhysicalAddressModal";
import MailingAddressModal from "./Modals/MailingAddressModal";

import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import PrimaryStar from "../../Shared/PrimaryStar";
export class FacilityInformationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpenPhysical: false,
      isModalOpenMailing: false,
      isModalOpenPhone: false,
    };
    this.toggleModalPhysical = this.toggleModalPhysical.bind(this);
    this.toggleModalMailing = this.toggleModalMailing.bind(this);
    this.toggleModalPhoneNo = this.toggleModalPhoneNo.bind(this);
  }
  toggleModalPhysical() {
    this.setState({ isModalOpenPhysical: !this.state.isModalOpenPhysical });
  }
  toggleModalMailing() {
    this.setState({ isModalOpenMailing: !this.state.isModalOpenMailing });
  }
  toggleModalPhoneNo() {
    this.setState({ isModalOpenPhone: !this.state.isModalOpenPhone });
  }
  openPhysicalAddressModal = () => {
    this.setState({ isModalOpenPhysical: true });
  };
  openMailingAddressModal = () => {
    //debugger
    this.setState({ isModalOpenMailing: true });
  };
  openPhoneModal = () => {
    this.setState({ isModalOpenPhone: true });
  };

  render() {
    return (
      <>
        <Card>
          <h5
            className="card-header c-pointer line-height-inherit"
            onClick={() =>
              this.props.setFacilityCardsVisibility(
                !this.props.facilityDashboardState.IsOpenFacilityCards
              )
            }
          >
            <i className="fas fa-building fa-fw"></i>

            {this.props.facilityName}
          </h5>

          <Collapse
            isOpen={this.props.facilityDashboardState.IsOpenFacilityCards}
          >
            {!this.props.facilityInfo && (
              <CardBody style={{ padding: "4rem" }}>
                <Spinner className="spinnerCard" />
              </CardBody>
            )}

            {this.props.facilityInfo && (
              <CardBody id="facility-info-body">
                <Row className="ml-0">
                  <Col md={8}>
                    {AuthService.canFacilityView(
                      PermissionFeature.FacilityAddress
                    ) && (
                      <>
                        <Row className="font-italic">
                          {this.props.facilityInfo && (
                            <h4tag className="text-primary-card">
                              Physical Address{" "}
                              {AuthService.canFacilityUpdate(
                                PermissionFeature.FacilityAddress
                              ) && (
                                <i
                                  class="fas fa-pencil-alt edit-pencil-icon"
                                  onClick={this.openPhysicalAddressModal}
                                ></i>
                              )}
                            </h4tag>
                          )}
                        </Row>

                        {this.props.facilityDashboardState
                          .facilityPhysicalAddresses &&
                          this.props.facilityDashboardState
                            .facilityPhysicalAddresses.length > 0 &&
                          this.props.facilityDashboardState.facilityPhysicalAddresses.map(
                            (item, index) =>
                              item.id > 0 && (
                                <Row key={index + 1}>
                                  <a
                                    // style={{ color: "#5b626b" }}
                                    target="_blank"
                                    href={
                                      "http://maps.google.com/?q=" +
                                      item.address1 +
                                      ", " +
                                      item.address2 +
                                      item.address1 +
                                      ", " +
                                      item.city +
                                      ", " +
                                      item.state +
                                      " " +
                                      item.zipcode +
                                      " "
                                    }
                                    >
                                    {`${item.address1}`}, {`${item.address2}`}
                                    <br />
                                    {`${item.city}, ${item.state}  ${item.zipcode}`}
                                  </a>
                                  {AuthService.canFacilityView(
                                    PermissionFeature.FacilityAddressLatLong
                                  ) &&
                                    this.props.facilityInfo.addressLatLong &&
                                    !_.isEmpty(
                                      this.props.facilityInfo.addressLatLong
                                    ) &&
                                    this.props.facilityInfo.addressLatLong.map(
                                      (x) => {
                                        if (x.addressid === item.id) {
                                          return (

                                              <div className="w-100" key={x.addressid}>
                                              <span className="pr-2 h6 small font-italic text-secondary">
                                                Latitude/Longitude{" "}
                                              </span>
                                              <span className="card-text">
                                                <p className="position-relative mb-0">
                                                  {/* item.isprimary && <PrimaryStar /> */}
                                                  <a
                                                    target="_blank"
                                                    href={
                                                      "https://www.google.com/maps?q=" +
                                                      x.latitude +
                                                      "," +
                                                      x.longitude
                                                    }
                                                  >
                                                    <span className="font-italic">
                                                      {x.latitude}/{x.longitude}
                                                    </span>
                                                  </a>
                                                </p>
                                              </span>
                                            </div>
                                          );
                                        }
                                        return null; // If no match found, return false
                                      }
                                    )}
                                </Row>
                              )
                          )}

                        <Row className="font-italic">
                          {this.props.facilityInfo && (
                            <h4tag className="text-primary-card">
                              Mailing Address{" "}
                              {AuthService.canFacilityView(
                                PermissionFeature.FacilityAddress
                              ) && (
                                <i
                                  class="fas fa-pencil-alt edit-pencil-icon"
                                  onClick={this.openMailingAddressModal}
                                >
                                  {" "}
                                </i>
                              )}
                            </h4tag>
                          )}
                        </Row>

                        {this.props.facilityDashboardState
                          .facilityMailingAddresses &&
                          this.props.facilityDashboardState
                            .facilityMailingAddresses.length > 0 &&
                          this.props.facilityDashboardState.facilityMailingAddresses.map(
                            (item, index) =>
                              item.id > 0 && (
                                <Row key={index + 1}>
                                  <a
                                    target="_blank"
                                    // style={{ color: "#5b626b" }}
                                    href={
                                      "http://maps.google.com/?q=" +
                                      item.address1 +
                                      ", " +
                                      item.address2 +
                                      item.address1 +
                                      ", " +
                                      item.city +
                                      ", " +
                                      item.state +
                                      " " +
                                      item.zipcode +
                                      " "
                                    }
                                  >
                                    {item.address1}, {item.address2}
                                    <br />
                                    {`${item.city}, ${item.state}  ${item.zipcode}`}
                                  </a>
                                  {AuthService.canFacilityView(
                                    PermissionFeature.FacilityAddressLatLong
                                  ) &&
                                    this.props.facilityInfo.addressLatLong &&
                                    !_.isEmpty(
                                      this.props.facilityInfo.addressLatLong
                                    ) &&
                                    this.props.facilityInfo.addressLatLong.map(
                                      (x) => {
                                        if (x.addressid === item.id) {
                                          return (
                                            <div className="w-100"  key={x.addressid}>
                                              <span className="pr-2 h6 small font-italic text-secondary">
                                                Latitude/Longitude{" "}
                                              </span>
                                              <span className="card-text">
                                                <p className="position-relative mb-0">
                                                  {/* item.isprimary && <PrimaryStar /> */}
                                                  <a
                                                    target="_blank"
                                                    href={
                                                      "https://www.google.com/maps?q=" +
                                                      x.latitude +
                                                      "," +
                                                      x.longitude
                                                    }
                                                  >
                                                    <span className="font-italic">
                                                      {x.latitude}/{x.longitude}
                                                    </span>
                                                  </a>
                                                </p>
                                              </span>
                                            </div>
                                          );
                                        }
                                        return null; // If no match found, return false
                                      }
                                    )}
                                </Row>
                              )
                          )}
                      </>
                    )}

                    {/* start of Phones */}

                    {AuthService.canFacilityView(
                      PermissionFeature.FacilityPhone
                    ) && (
                      <>
                        <Row className="font-italic">
                          {this.props.facilityInfo && (
                            <h4tag className="text-primary-card">
                              Phone Numbers{" "}
                              {AuthService.canFacilityUpdate(
                                PermissionFeature.FacilityPhone
                              ) && (
                                <i
                                  class="fas fa-sm fa-pencil-alt edit-pencil-icon"
                                  onClick={this.openPhoneModal}
                                ></i>
                              )}
                            </h4tag>
                          )}
                        </Row>

                        {this.props.facilityDashboardState
                          .facilityPhoneNumbers &&
                          this.props.facilityDashboardState.facilityPhoneNumbers
                            .length > 0 &&
                          this.props.facilityDashboardState.facilityPhoneNumbers.map(
                            (item, index) =>
                              item.id > 0 && (
                                <div
                                  className="position-relative"
                                  key={index + 1}
                                >
                                  <Row>
                                    {/* {<PrimaryStar />}{" "} */}
                                    <a
                                      // style={{ color: "#5b626b" }}
                                      href={"tel:" + item.number}
                                    >
                                      {item.number !== "" ? (
                                        <span>
                                          {`${item.phonetypeText}: ${item.number}`}{" "}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <br />
                                    </a>
                                  </Row>
                                </div>
                              )
                          )}
                      </>
                    )}

                    <Row className="font-italic">
                      {this.props.facilityInfo && (
                        <h4tag className="text-primary-card">
                          BUEs/Members
                        </h4tag>
                      )}
                    </Row>
                    <Row>
                      <h5tag>
                        <h5tag>
                          {this.props.facilityInfo &&
                            `${this.props.facilityInfo.bueObject.totalMembers} Members | ${this.props.facilityInfo.bueObject.totalBUE} BUEs | ${this.props.facilityInfo.bueObject.percentMembers}%`}
                        </h5tag>
                      </h5tag>
                    </Row>
                  </Col>
                  <Col md={4}>
                    {AuthService.canFacilityView(
                      PermissionFeature.FacilityRegion
                    ) &&
                      this.props.facilityInfo &&
                      this.props.facilityInfo.region && (
                        <>
                          <Row className="font-italic">
                            <h4tag className="text-primary-card">Region</h4tag>
                          </Row>
                          <Row>
                            <h5tag> {this.props.facilityInfo.region}</h5tag>
                          </Row>
                        </>
                      )}

                    {AuthService.canFacilityView(
                      PermissionFeature.FacilityLevel
                    ) &&
                      this.props.facilityInfo &&
                      this.props.facilityInfo.level && (
                        <>
                          <Row className="font-italic">
                            <h4tag className="text-primary-card">Level</h4tag>
                          </Row>
                          <Row>
                            <h5tag> {this.props.facilityInfo.level}</h5tag>
                          </Row>
                        </>
                      )}

                    {AuthService.canFacilityView(
                      PermissionFeature.FacilityType
                    ) &&
                      this.props.facilityInfo &&
                      this.props.facilityInfo.type && (
                        <>
                          <Row className="font-italic">
                            <h4tag className="text-primary-card">Type</h4tag>
                          </Row>
                          <Row>
                            <h5tag> {this.props.facilityInfo.type}</h5tag>
                          </Row>
                        </>
                      )}

                    {AuthService.canFacilityView(
                      PermissionFeature.FacilitySrfStatus
                    ) &&
                      this.props.facilityInfo &&
                      this.props.facilityInfo.srfrxiistatus && (
                        <>
                          <Row className="font-italic">
                            <h4tag className="text-primary-card">SRF12</h4tag>
                          </Row>
                          <Row>
                            <h5tag>
                              {" "}
                              {this.props.facilityInfo.srfrxiistatus}
                            </h5tag>
                          </Row>
                        </>
                      )}

                    {AuthService.canFacilityView(
                      PermissionFeature.FacilityLmNumber
                    ) &&
                      this.props.facilityInfo &&
                      this.props.facilityInfo.lmivnumber && (
                        <>
                          <Row className="font-italic">
                            <h4tag className="text-primary-card">
                              LM-4 File Number
                            </h4tag>
                          </Row>
                          <Row>
                            <h5tag> {this.props.facilityInfo.lmivnumber}</h5tag>
                          </Row>
                        </>
                      )}
                  </Col>
                </Row>
              </CardBody>
            )}
          </Collapse>
        </Card>
        <PhysicalAddressModal
          facilityId={this.props.facilityId}
          isModalOpen={this.state.isModalOpenPhysical}
          toggleModal={this.toggleModalPhysical}
        />
        <MailingAddressModal
          facilityId={this.props.facilityId}
          isModalOpen={this.state.isModalOpenMailing}
          toggleModal={this.toggleModalMailing}
        />
        <PhoneModal
          facilityId={this.props.facilityId}
          isModalOpen={this.state.isModalOpenPhone}
          toggleModal={this.toggleModalPhoneNo}
          phoneTypes={
            this.props.facilityInfo && this.props.facilityInfo.phoneTypes
          }
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    facilityDashboardState: state.facilityDashboard,
  }),
  {
    ...actions,
  }
)(FacilityInformationCard);
